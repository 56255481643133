import { FC } from 'react';
import { SelectOptionButtonProps } from './types';

/**
 * Lightweight select option component for fast rendering
 * long lists of simple text options.
 */
export const FastSelectOption: FC<SelectOptionButtonProps> = ({
  height,
  isSelected,
  text,
  id,
  onChange,
  isLargeScreen,
  testId,
  ...restProps
}) => {
  return (
    <li
      {...restProps}
      data-id={id}
      data-testid={testId}
      style={{
        color: '#fff',
        fontSize: isLargeScreen ? '1.4rem' : '1.7rem',
        height,
        backgroundColor: isSelected ? '#303030' : '#1a1a1a',
        padding: `0 ${isLargeScreen ? '1rem' : '1.6rem'}`,
        lineHeight: height,
        cursor: 'pointer',
      }}
    >
      {text}
    </li>
  );
};
